/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Fade from 'react-bootstrap/Fade';
import { Center } from '@chakra-ui/react';
import { Section, TitleText, TabPane, TabContent } from './styles';
import Tabs from './Tabs/Tabs';
import Portfolio from './Portfolio/Portfolio';
import { useMyWork } from './MyWorkContext';
import { Loader } from '@components/Shared/Loader';
import FadeInWhenVisible from '@components/Shared/FadeInWhenVisible/FadeInWhenVisible';
import { NavigationButton } from '@components/Shared/NavigationButton';

const MyWork: React.FC = () => {
  const myWork = useMyWork();

  const [activeTab, setActiveTab] = useState(Object.keys(myWork)[0] ?? '');

  if (!myWork) return <Loader />;

  return (
    <Section className="pt-5">
      <FadeInWhenVisible>
        <Container>
          <Row className="mb-4">
            <Col xs={12} sm={12} md={12} lg={12} className="text-center">
              <TitleText>
                <span>My</span>
                <span> Blog</span>
              </TitleText>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <div>
                <Tabs setActiveTab={setActiveTab} />
              </div>
            </Col>
          </Row>
          <TabContent
            className="text-center mt-5"
            items={myWork[activeTab]?.length ?? 0}
          >
            {Object.entries(myWork).map(([category, myWorks]) => (
              <Fade in={category === activeTab} key={category}>
                <TabPane isActive={category === activeTab}>
                  <Row>
                    {myWorks.map((portfolio) => (
                      <Portfolio key={portfolio._id ?? ''} {...portfolio} />
                    ))}
                  </Row>
                </TabPane>
              </Fade>
            ))}
          </TabContent>
          <Center mt={7} mb="53px">
            <NavigationButton href="/blog" maxW="350px">
              Click here for the Oh so Free Blog
            </NavigationButton>
          </Center>
        </Container>
      </FadeInWhenVisible>
    </Section>
  );
};

export default MyWork;
