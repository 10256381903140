import styled from 'styled-components';
import Image from 'react-bootstrap/Image';
import { Col } from 'react-bootstrap';

export const PortfolioLink = styled.a`
  position: relative;
  display: block;
  max-width: 400px;
  margin: 0 auto;
  cursor: pointer;
  height: 200px;
`;

export const PortfolioImage = styled(Image)`
  width: 100%;
  height: 100% !important;
`;

export const PortfolioHover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all ease 0.5s;
  opacity: 0;
  background: ${({ theme }) => theme.transparent(theme.colors.secondary, 70)};
  z-index: 1;
`;

export const PortfolioHoverContent = styled.div`
  font-size: 20px;
  position: absolute;
  top: 40%;
  width: 100%;
  height: 20px;
  margin-top: -12px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  i {
    margin-top: -12px;
  }
`;

export const PortfolioCaption = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 25px;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 600;
  text-align: center;
`;

export const PortfolioCaptionText = styled.div`
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Wrapper = styled.div`
  &:hover {
    ${PortfolioHover} {
      opacity: 1;
    }
  }
`;

export const StyledCol = styled(Col)`
  ${({ theme }) => theme.BreakpointMobile`
    margin-bottom: 25px;
  `};
`;
