import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { useMyWork } from '../MyWorkContext';
import { NavItem, NavLink } from './styles';
import { ITabListProps } from './interfaces';

const TabsList: React.FC<ITabListProps> = ({ setActiveTab }) => {
  const myWork = useMyWork();
  const categories = Object.keys(myWork);
  const onClick = (prop: React.MouseEvent<HTMLLinkElement>) => {
    setActiveTab(prop.currentTarget.title);
  };
  return (
    <Nav
      defaultActiveKey={categories[0]}
      role="tablist"
      className="justify-content-center"
    >
      {categories.map((category) => {
        return (
          <NavItem key={category}>
            <NavLink
              data-toggle="tab"
              role="tab"
              aria-expanded="true"
              onClick={onClick}
              title={category}
              eventKey={category}
            >
              {category}
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );
};

export default TabsList;
