import styled from 'styled-components';
import { ITabContentProps, ITabPaneProps } from './interfaces';

export const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
`;

export const TitleText = styled.h1`
  span {
    &:first-child {
      font-family: ${({ theme }) => theme.fonts.montserrat};
      font-weight: 300;
      color: ${({ theme }) => theme.colors.grey.light};
    }
    &:last-child {
      font-family: ${({ theme }) => theme.fonts.montserrat};
      font-weight: 700;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const TabPane = styled.div<ITabPaneProps>`
  width: 100%;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

export const TabContent = styled.div<ITabContentProps>`
  position: relative;
`;
