import React from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { IMyWork } from '../interfaces';
import {
  PortfolioLink,
  PortfolioHover,
  PortfolioCaption,
  PortfolioCaptionText,
  Wrapper,
  StyledCol,
} from './styles';

const Portfolio: React.FC<IMyWork> = (portfolioItem) => {
  return (
    <StyledCol sm={12} md={6} lg={4}>
      <Link
        href={`/blog/${portfolioItem.slug?.current}`}
        passHref
        legacyBehavior
      >
        <Wrapper>
          <PortfolioLink>
            <PortfolioHover />
            <Image
              layout="fill"
              src={portfolioItem.mainPhoto?.asset?.url ?? ''}
              placeholder="blur"
              blurDataURL={portfolioItem.mainPhoto?.asset?.metadata?.lqip ?? ''}
            />
          </PortfolioLink>
          <PortfolioCaption>
            <PortfolioCaptionText className="text-muted">
              {portfolioItem.title}
            </PortfolioCaptionText>
          </PortfolioCaption>
        </Wrapper>
      </Link>
    </StyledCol>
  );
};

export default Portfolio;
