import styled from 'styled-components';
import Nav from 'react-bootstrap/Nav';

export const NavItem = styled(Nav.Item)`
  margin-bottom: 8px;
  :hover {
    cursor: pointer;
  }
  .active {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white} !important;
    border-radius: 15px;
  }
`;

export const NavLink = styled(Nav.Link)`
  padding: 5px 18px;
  color: #868686 !important;
  border-radius: 30px;
  font-family: ${({ theme }) => theme.fonts.montserrat};
  display: inline-block;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
`;
